import { action, computed, observable, runInAction } from 'mobx'
import DealershipAuthService from 'modules/Dealership/services/DealershipAuthService'
import DealershipDataService from 'modules/Dealership/services/DealershipDataService'
import AsyncStore from 'core/stores/AsyncStore'
import { convertExpirationToMilliseconds } from 'core/util/utils'

class DealershipAuthStore extends AsyncStore {
    @observable dealershipLogoutModal = false
    @observable isAuthenticated = false
    @observable sessionId = null
    @observable hasSelectedDealershipAndBranch = false

    constructor(rootStore) {
        super()
        this.rootStore = rootStore
        this.init()
    }

    @action
    init() {
        if (DealershipAuthService.getSessionId()) {
            this.doBasicAuth()
            this.setExpirationTime(DealershipAuthService.getExpirationTime())
        }

        const sessionId = DealershipDataService.getCreditUUID()
        if (sessionId) {
            this.setSessionId(sessionId)
        }
    }

    @action
    setExpirationTime(unixTime) {
        const expirationTime = convertExpirationToMilliseconds(unixTime)

        if (expirationTime > 0) {
            this.expirationTimeOut = setTimeout(() => {
                this.logout()
            }, expirationTime)
        } else {
            this.logout()
        }
    }

    @action
    openLogoutModal() {
        this.dealershipLogoutModal = true
    }

    @action
    hideLogoutModal() {
        this.dealershipLogoutModal = false
    }

    @action
    authenticate() {
        this.isAuthenticated = true
    }

    @action
    setSessionId() {
        this.sessionId = true
    }

    @action
    doBasicAuth() {
        this.authenticate()
    }

    @computed
    get isFullyAuthenticated() {
        return this.sessionId && this.hasSelectedDealershipAndBranch
    }

    @action
    doFullyAuth(creditUUID) {
        DealershipDataService.setCreditUUID(creditUUID)
        this.setSelectedDealershipAndBranch()
        this.setSessionId(creditUUID)
    }

    @action
    setSelectedDealershipAndBranch() {
        this.hasSelectedDealershipAndBranch = true
    }

    @action
    clearSelectedDealershipAndBranch() {
        this.hasSelectedDealershipAndBranch = false
    }

    async clearAuthentication() {
        this.isAuthenticated = false
        this.sessionId = null
        this.clearSelectedDealershipAndBranch()
        DealershipAuthService.deauthenticate()
    }

    @action
    async logout() {
        await this.clearAuthentication()
        this.hideLogoutModal()
        clearTimeout(this.expirationTimeOut)
    }

    @action
    async loginWithCredentials(rut, password) {
        await DealershipAuthService.authenticateWithCredentials(rut, password)
        this.doBasicAuth()
        this.setExpirationTime(DealershipAuthService.getExpirationTime())
    }

    @action
    async loginWithSignedURL(signedURL) {
        await DealershipAuthService.authenticateWithSignedURL(signedURL)
        this.doBasicAuth()
        this.setExpirationTime(DealershipAuthService.getExpirationTime())
    }

    static sessionRequest(dealership, branch, productType) {
        return new Promise(async (resolve, reject) => {
            try {
                const { sessionId } = await DealershipDataService.sessionRequest()
                DealershipDataService.setCreditUUID(sessionId)
                await DealershipDataService.sendData({ dealership, branch, productType })
                runInAction(() => {
                    resolve(sessionId)
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    @action
    async createSession(dealership, branch, sellerProduct) {
        try {
            const sessionId = await DealershipAuthStore.sessionRequest(
                dealership,
                branch,
                sellerProduct
            )
            this.doFullyAuth(sessionId)
        } catch (e) {
            console.log(e)
        }
    }
}

export default DealershipAuthStore

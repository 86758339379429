export const USER = '/usuario'

const SIGN_IN_BASE = `${USER}/registro`
export const LOGIN = `${USER}/login`
export const SIGN_IN = `${SIGN_IN_BASE}/:personId/:agreedLoanId`
export const PRE_IDENTITY = `${USER}/pre-identidad`
export const DOCUMENTATION = `${USER}/documentacion`
export const FORGOT_PASSWORD = `${USER}/olvide-contraseña`
export const CHANGE_PASSWORD = `${USER}/cambiar-contraseña`
export const LOAD_DOCUMENTS = `${USER}/documentos/:id`
export const SELECT_QUOTE = `${USER}/seleccionar-cotizacion`
export const REFERENCES = `${USER}/referencias/:categoryId/:id`
export const DOCUMENTATION_SUCCESS = `${USER}/felicitaciones`

export const loadDocumentsRoute = id => {
    return `${LOAD_DOCUMENTS.replace(':id', id)}`
}

export const signInRoute = (personId, agreedLoanId) => {
    return `${SIGN_IN.replace(':personId', personId).replace(':agreedLoanId', agreedLoanId)}`
}

export const isSignInRoute = route => {
    return (
        route.includes(SIGN_IN_BASE) &&
        route.split('/').length === 5 &&
        route.split('/')[route.split('/').length - 1]
    )
}

export const referencesRoute = (categoryId, referenceId) => {
    return `${REFERENCES.replace(':categoryId', categoryId).replace(':id', referenceId)}`
}

// ROUTE ORDER BY NAME :) !!
export default {
    USER,
    CHANGE_PASSWORD,
    DOCUMENTATION_SUCCESS,
    DOCUMENTATION,
    FORGOT_PASSWORD,
    LOAD_DOCUMENTS,
    LOGIN,
    PRE_IDENTITY,
    SELECT_QUOTE,
    SIGN_IN,
}

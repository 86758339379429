import AuthStore from 'modules/User/stores/AuthStore'
import RouterStore from 'core/stores/RouterStore'
import axiosInterceptors from 'core/util/axiosInterceptors'
import DealershipAuthStore from 'modules/Dealership/stores/DealershipAuthStore'

class RootStore {
    constructor() {
        this.routerStore = new RouterStore()
        this.authStore = new AuthStore(this)
        this.dealershipAuthStore = new DealershipAuthStore(this)
    }

    static async build() {
        const NewRootStore = new RootStore()
        axiosInterceptors(NewRootStore.authStore)
        return NewRootStore
    }

    reset() {
        this.routerStore.clearAll()
    }
}

export default RootStore

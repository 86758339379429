import React, { useState, useEffect } from 'react'
import RootStore from 'core/stores/RootStore'

const StoreContext = React.createContext(null)

const RootStoreProvider = props => {
    const [isReady, setIsReady] = useState(false)
    const [rootStore, setRootStore] = useState(null)

    useEffect(() => {
        RootStore.build().then(NewRootStore => {
            setRootStore(NewRootStore)
            setIsReady(true)
        })
    }, [])

    if (!isReady) {
        return null
    }

    return <StoreContext.Provider value={rootStore} {...props} />
}

const useRootStore = () => React.useContext(StoreContext)

export { RootStoreProvider, useRootStore }

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import common from 'core/lang/common.json'
import carValue from 'core/lang/carValue.json'
import documentationSuccess from 'modules/User/lang/documentationSuccess.json'
import documentation from 'modules/User/lang/documentation.json'
import SEO from 'core/lang/SEO.json'
import FAQ from 'core/lang/FAQ.json'
import preIdentity from 'modules/User/lang/preIdentity.json'
import loadImages from 'modules/User/lang/loadImages.json'
import selectQuote from 'modules/User/lang/selectQuote.json'
import references from 'modules/User/lang/references.json'
import result from 'core/lang/result.json'
import forgotPassword from 'modules/User/lang/forgotPassword.json'
import login from 'core/lang/login.json'
import verifying from 'core/lang/verifying.json'
import preCreditRequest from 'core/lang/preCreditRequest.json'
import additionalPersonalData from 'core/lang/additionalPersonalData.json'
import personalData from 'core/lang/personalData.json'
import privacyAuth from 'core/lang/privacyAuth.json'
import signin from 'modules/User/lang/signin.json'

i18n.use(initReactI18next).init({
    fallbackLng: 'es',
    lng: 'es',

    ns: [],
    defaultNS: 'common',

    debug: process.env.REACT_APP_TRANSLATIONS_DEBUG === 'true',

    interpolation: {
        escapeValue: false, // not needed for react!!
    },

    react: {
        wait: true,
        useSuspense: false,
    },

    resources: {
        es: {
            common,
            carValue,
            FAQ,
            SEO,
            login,
            documentationSuccess,
            documentation,
            references,
            loadImages,
            result,
            selectQuote,
            forgotPassword,
            preCreditRequest,
            preIdentity,
            additionalPersonalData,
            personalData,
            verifying,
            privacyAuth,
            signin,
        },
    },
})

export default i18n

import React, { useEffect } from 'react'
import { Router as ReactRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { observer } from 'mobx-react'
import ScrollMemory from 'react-router-scroll-memory'
import { getRoute } from 'core/routing/paths'
import { useRootStore } from 'core/providers/RootStoreProvider'
import { createBrowserHistory } from 'history'
import { syncHistoryWithStore } from 'core/stores/RouterStore'
import { ClientModule, DealershipModule, UserModule, PrivacyAuthComponent } from './routes'
import { CLIENT, DEALERSHIP, USER, PRIVACY_AUTH } from './paths'

const Router = () => {
    const { routerStore, dealershipAuthStore } = useRootStore()
    const browserHistory = createBrowserHistory()
    const history = syncHistoryWithStore(browserHistory, routerStore)

    useEffect(() => {
        if (
            dealershipAuthStore.isAuthenticated &&
            history.location.pathname !==
                getRoute(dealershipAuthStore.isAuthenticated, history.location.pathname)
        )
            history.push(getRoute(dealershipAuthStore.isAuthenticated, history.location.pathname))
    }, [])

    return (
        <ReactRouter history={history}>
            <ScrollMemory />
            <React.Suspense fallback={null}>
                <Switch>
                    <Route path={PRIVACY_AUTH} component={PrivacyAuthComponent} />
                    <Route path={DEALERSHIP} component={DealershipModule} />
                    <Route path={USER} component={UserModule} />
                    <Route path={CLIENT} component={ClientModule} />
                </Switch>
            </React.Suspense>
        </ReactRouter>
    )
}

export default observer(Router)

import axios from 'axios'
import { API_URL } from 'core/services/config'
import AuthUser from 'core/models/AuthUser'
import JWT from 'core/models/JWT'
import ClientAuthService from 'modules/Client/services/ClientAuthService'

export const SESSION_ID = 'user-session-id'

class AuthService {
    createAuthUser = response => {
        const authUser = AuthUser.fromJson({
            ...response.data.data,
            ...response.data.data.user,
        })

        AuthService.setSessionId(authUser.token, authUser.expiration)
        ClientAuthService.clearSessionId()
        ClientAuthService.clearCreditUUID()

        return authUser
    }

    authenticate = (email, password) =>
        axios
            .post(`${API_URL}/authentication/login?with=agreedLoans`, {
                email,
                password,
            })
            .then(response => this.createAuthUser(response))

    refreshToken = () => {
        return axios.post(`${API_URL}/authentication/refresh`).then(response => {
            const token = JWT.fromJson(response.data.data)

            return {
                token: token.accessToken,
                expiration: token.expiration,
            }
        })
    }

    carQuestion = answer => {
        const body = { answer }
        return axios.post(`${API_URL}/users/have-car-answer`, body).then(response => response)
    }

    logout = () => {
        return axios.post(`${API_URL}/authentication/logout`).then(() => AuthService.clearAuth())
    }

    loadAuthUserFromBrowser = () => {
        const token = AuthService.getSessionId()
        const expiration = AuthService.getExpirationTime()

        if (token) {
            return this.getAuthUserByToken(token, expiration)
        }

        return null
    }

    static clearAuth() {
        return sessionStorage.removeItem(SESSION_ID)
    }

    static getSessionId() {
        const item = sessionStorage.getItem(SESSION_ID)
        if (!item) {
            return null
        }
        return JSON.parse(item).value
    }

    static setSessionId(value, expiry) {
        const item = { value, expiry }
        sessionStorage.setItem(SESSION_ID, JSON.stringify(item))
    }

    static getExpirationTime() {
        const item = sessionStorage.getItem(SESSION_ID)
        if (!item) {
            return null
        }
        return JSON.parse(item).expiry
    }

    signIn = (
        personId = null,
        agreedLoanId = null,
        password = null,
        password_confirmation = null
    ) => {
        const body = {
            personId,
            agreedLoanId,
            password,
            password_confirmation,
        }
        return axios
            .post(`${API_URL}/authentication/register?with=agreedLoans`, body)
            .then(response => this.createAuthUser(response))
    }

    signInWithProvider = (personId = null, provider = null, accessToken = null) => {
        const body = {
            personId,
            provider,
            temporalToken: accessToken,
        }
        return axios
            .post(`${API_URL}/authentication/provider?with=agreedLoans`, body)
            .then(response => this.createAuthUser(response))
    }

    forgotPassword = email => {
        return axios.post(`${API_URL}/password/email`, { email }).then(res => res.data.data)
    }

    validateSignInParams = (personId, agreedLoanId) => {
        return axios
            .post(`${API_URL}/authentication/register-validation`, { personId, agreedLoanId })
            .then(res => res.data.data)
    }

    validateChangePasswordParams = (email, token) => {
        return axios
            .post(`${API_URL}/reset-password-validation`, { email, token })
            .then(res => res.data.data)
    }

    changePassword = (email, token, pass, passConfirm) => {
        return axios
            .post(`${API_URL}/password/reset`, {
                email,
                token,
                password: pass,
                password_confirmation: passConfirm,
            })
            .then(res => res.data.data)
    }

    getAuthUserByToken = (token, expiration) => {
        return axios
            .get(`${API_URL}/users/profile?with=agreedLoans`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                const authUser = AuthUser.fromJson({
                    ...response.data.data,
                    ...response.data.data.user,
                })
                authUser.updateAuth(token, expiration)
                authUser.setCurrentAgreedLoan(response.data.data.currentAgreedLoan)

                return authUser
            })
    }
}

export default AuthService

import moment from 'moment'
import { MAX_TIMEOUT_TIME, MAX_CAR_AGE_DEFAULT } from 'core/constants'
import SelectOption from 'core/presentation/SelectOption'

// Funcion para dar vuelta un número
const reverseNumber = input => {
    return [].map
        .call(input, x => {
            return x
        })
        .reverse()
        .join('')
}

// extracts expiration date from JWToken
const getJWTExpDate = token => moment(JSON.parse(atob(token.split('.')[1])).exp * 1000)

const getTokenExpirationDate = token => {
    const tokenExpirationDate = getJWTExpDate(token)

    // Remaining time until token expires
    return moment.utc(moment(tokenExpirationDate).diff(moment())).valueOf()
}

const getLogoutTime = token => {
    return getTokenExpirationDate(token) - 10000
}

// Funcion que quita los puntos un numero (string)
const plainNumber = (number, toInt = false) => {
    if (number) {
        const value = number
            .toString()
            .split('.')
            .join('')

        if (toInt) {
            return parseInt(value, 0)
        }

        return value
    }

    return null
}

// Funcion apra agregar puntos a un numero (string) cada tres caracteres (números)
const addDots = value => {
    if (!value) {
        return 0
    }

    const plain = plainNumber(value.toString())
    const reversed = reverseNumber(plain)
    const reversedWithDots = reversed.match(/.{1,3}/g).join('.')

    return reverseNumber(reversedWithDots)
}

export const getValueToParse = (option, _property) => {
    if (_property && _property === '__raw__') {
        return option
    }
    // eslint-disable-next-line no-prototype-builtins
    if (_property && option.hasOwnProperty(_property)) {
        return option[_property]
    }
    return option.name
}

// Parsea un valor al formato de react select
export const parseValueToSelect = value => {
    if (!value) {
        return ''
    }

    if (typeof value === 'object') {
        return {
            value: getValueToParse(value, value.value),
            label: getValueToParse(value, value.label),
        }
    }

    return { value, label: value }
}

// / parsea un arreglo de datos al formato requerido por reeact select
export const parseDataToOptions = (data, property, getValue = getValueToParse) => {
    if (!Array.isArray(data)) {
        throw new Error('Need to pass an array of objects')
    }

    if (typeof getValue !== 'function') {
        throw new Error('Need to pass a function')
    }

    if (property && typeof property === 'object') {
        return data.map(option => ({
            value: getValue(option, property.value),
            label: getValue(option, property.label),
        }))
    }

    return data.map(option => ({
        value: getValue(option, property),
        label: getValue(option, property),
    }))
}

const convertExpirationToMilliseconds = unixTime => {
    let expirationTime = (unixTime - Math.round(+new Date() / 1000)) * 1000
    if (expirationTime > MAX_TIMEOUT_TIME) {
        expirationTime = MAX_TIMEOUT_TIME
    }

    return expirationTime
}

const numeric = value => {
    if (!value) {
        return ''
    }
    return value.toString().replace(/\D/g, '')
}

const parsePhone = value => {
    if (!value) {
        return ''
    }

    const num = numeric(value)

    if (num) {
        return num
            .replace(/ /g, '')
            .trim()
            .substring(0, 9)
    }

    return ''
}

const formatRut = value => {
    return value.replace(/[.-]/g, '').replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
}

const formatMoney = value => {
    if (value) {
        return `$ ${addDots(value)}`
    }

    return '$ 0'
}

const maskDate = value => {
    const v = value.replace(/\D/g, '').slice(0, 10)
    if (v.length >= 5) {
        return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
    }
    if (v.length >= 3) {
        return `${v.slice(0, 2)}/${v.slice(2)}`
    }
    return v
}

const capitalize = s => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const isValidDate = value => {
    return value && moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') === value
}

const isValidRut = rut => {
    return rut.replace(/\./g, '').replace(/-/g, '').length <= 9
}

const calculateAge = birthDay => {
    if (!birthDay) {
        return null
    }

    const getDate = date => moment(date, 'DD/MM/YYYY').toDate()

    return moment().diff(getDate(birthDay), 'years')
}

const getPercent = (value, percent) => (value ? (parseFloat(value) * percent) / 100 : null)

const getYearsWithLimits = maxCarAge => {
    const years = []
    if (Number.isInteger(maxCarAge)) {
        const currentDate = moment()
        const max = currentDate.year()
        const min = max - (maxCarAge || MAX_CAR_AGE_DEFAULT)

        // eslint-disable-next-line no-plusplus
        for (let year = max; year >= min; year--) {
            years.push(SelectOption.fromJson({ value: year }))
        }
    }
    return years
}

export {
    addDots,
    isValidRut,
    reverseNumber,
    calculateAge,
    plainNumber,
    getJWTExpDate,
    getTokenExpirationDate,
    convertExpirationToMilliseconds,
    getLogoutTime,
    formatMoney,
    parsePhone,
    formatRut,
    numeric,
    maskDate,
    isValidDate,
    capitalize,
    getPercent,
    getYearsWithLimits,
}

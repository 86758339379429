class SelectOption {
    constructor(id, value) {
        this.id = id
        this.value = value
    }

    static fromJson({ value, id = value }) {
        if (value !== null && id != null) {
            return new SelectOption(id, value)
        }

        return null
    }
}

export default SelectOption

const CREATED_STATUS = 'created'

class PrivacyAcceptance {
    privacyAuthAcceptedAt = null
    confirmationCodeValidatedAt = null
    isValid = false

    constructor(
        privacyAuthAcceptedAt = null,
        confirmationCodeValidatedAt = null,
        externalPrivacyAuthAcceptedAt = null,
        email = null,
        phoneNumber = null,
        lastSendAt = null,
        status = null,
        isValid = true
    ) {
        this.privacyAuthAcceptedAt = privacyAuthAcceptedAt
        this.confirmationCodeValidatedAt = confirmationCodeValidatedAt
        this.externalPrivacyAuthAcceptedAt = externalPrivacyAuthAcceptedAt
        this.email = email
        this.phoneNumber = phoneNumber
        this.lastSendAt = lastSendAt
        this.status = status
        this.isValid = isValid
    }

    get isConfirmedPrivacyPolicy() {
        return !!this.privacyAuthAcceptedAt
    }

    get isCreated() {
        return this.status === CREATED_STATUS
    }

    get isConfirmedBureauCode() {
        return !!this.confirmationCodeValidatedAt
    }

    get isConfirmedExternalPrivacyPolicy() {
        return !!this.externalPrivacyAuthAcceptedAt
    }

    static fromJson(json) {
        let privacyAuthAcceptedAt = null
        let confirmationCodeValidatedAt = null
        let externalPrivacyAuthAcceptedAt = null
        let email = null
        let phoneNumber = null
        let updateAt = null
        let status = null

        if (json) {
            privacyAuthAcceptedAt = json.privacyAuthAcceptedAt
            confirmationCodeValidatedAt = json.confirmationCodeValidatedAt
            email = json.email
            phoneNumber = json.phoneNumber || json.phone
            externalPrivacyAuthAcceptedAt = json.externalPrivacyAuthAcceptedAt
            updateAt = json.lastSendAt
            status = json.status
        }

        return new PrivacyAcceptance(
            privacyAuthAcceptedAt,
            confirmationCodeValidatedAt,
            externalPrivacyAuthAcceptedAt,
            email,
            phoneNumber,
            updateAt,
            status,
            !!json
        )
    }
}

export default PrivacyAcceptance

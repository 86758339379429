class SellerProduct {
    constructor(id, name) {
        this.id = id
        this.name = name
    }

    static fromJson({ id, name }) {
        if (id !== null && name != null) {
            return new SellerProduct(id, name)
        }
        return null
    }
}

export default SellerProduct

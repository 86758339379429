import moment from 'moment'

class JWT {
    constructor(accessToken, expiration, tokenType) {
        this.accessToken = accessToken
        // UNIX EXPIRATION
        this.expiration = expiration
        this.tokenType = tokenType
    }

    static fromJson({ accessToken, expiresAt, tokenType }) {
        const unixExpiration = moment(expiresAt).unix()

        return new JWT(accessToken, unixExpiration, tokenType)
    }
}

export default JWT

import { observable, action, computed, runInAction } from 'mobx'
import AuthService from 'modules/User/services/AuthService'
import AsyncStore from 'core/stores/AsyncStore'
import { convertExpirationToMilliseconds } from 'core/util/utils'

class AuthStore extends AsyncStore {
    @observable authUser = null
    @observable logoutModal = false
    @observable isLoadingCarQuestion = false
    @observable logoutLoading = false
    @observable confirmLogoutModal = false

    constructor(rootStore) {
        super()

        this.rootStore = rootStore
        this.authService = new AuthService()
        this.isLoading = false

        if (AuthService.getSessionId()) {
            this.loadAuthFromBrowser()
        }
    }

    async sendCarAnswer(answer) {
        this.isLoadingCarQuestion = true

        try {
            await this.authService.carQuestion(answer)

            try {
                this.rootStore.preCreditStore.exponeaTrackingService.trackCarQuestion(answer)
            } catch (e) {
                console.error(e)
            }

            runInAction(() => {
                this.isLoadingCarQuestion = false
                this.authUser.setAskCarQuestion(false)
            })
        } catch (e) {
            this.authUser.setAskCarQuestion(false)
        }
    }

    async loadAuthFromBrowser() {
        this.preRequest()

        try {
            const authUser = await this.authService.loadAuthUserFromBrowser()

            runInAction(() => {
                // if token exists and it's not expired, renews credentials
                if (
                    authUser &&
                    AuthStore.isValidSessionTime(AuthStore.sessionTime(authUser?.expiration))
                ) {
                    return this.authenticate(authUser).then(() => {
                        this.onSuccessRequest()
                        return Promise.resolve()
                    })
                }
                this.onSuccessRequest()
            })
        } catch (e) {
            this.logout()
            this.onSuccessRequest()
        }
        return Promise.resolve()
    }

    @action
    basicLogin(username, password, rememberMe) {
        return this.authService.authenticate(username, password, rememberMe).then(authUser => {
            this.authenticate(authUser)

            return authUser.token
        })
    }

    @action
    signInWithProvider(userId, provider, accessToken) {
        return this.authService.signInWithProvider(userId, provider, accessToken).then(authUser => {
            this.authenticate(authUser)

            return authUser.token
        })
    }

    @action
    authenticate(authUser) {
        this.updateAuthUser(authUser)
        const { expiration } = authUser

        this.setLogoutTime(expiration)

        return Promise.resolve()
    }

    updateToken(token, expiration) {
        if (this.authUser) {
            this.authUser.updateAuth(token, expiration)
            this.setLogoutTime(expiration)
        }

        if (AuthService.getSessionId() !== token) {
            AuthService.setSessionId(token, expiration)
        }
    }

    showConfirmLogoutModal() {
        this.confirmLogoutModal = true
    }

    hideConfirmLogoutModal() {
        this.confirmLogoutModal = false
    }

    @action
    showLogoutModal() {
        this.logoutModal = true
    }

    @action
    hideLogoutModal() {
        this.logoutModal = false
    }

    @action
    clearTimeout() {
        clearTimeout(this.logoutTime)
    }

    static sessionTime(expiration) {
        return convertExpirationToMilliseconds(expiration)
    }

    static isValidSessionTime(sessionTime) {
        return sessionTime > 0
    }

    @action
    setLogoutTime(expiration) {
        this.clearTimeout()
        const token = AuthService.getSessionId()
        const expirationTime = AuthStore.sessionTime(expiration)
        if (AuthStore.isValidSessionTime(expirationTime)) {
            this.logoutTime = setTimeout(() => {
                if (token === this.authUser.token) {
                    this.logout()
                    this.showLogoutModal()
                } else {
                    this.updateToken(token)
                }
            }, expirationTime)
        } else {
            this.logout()
        }
    }

    @action
    updateAuthUser(authUser) {
        this.authUser = authUser
    }

    @action
    keepAlive(userActivity = false) {
        this.authService
            .refreshToken()
            .then(token => {
                this.updateToken(token, userActivity)
            })
            .catch(() => {
                this.logout()
            })
    }

    @action
    signIn(user, agreedLoanId, password, passwordConfirmation) {
        return this.authService
            .signIn(user, agreedLoanId, password, passwordConfirmation)
            .then(authUser => {
                this.authenticate(authUser)

                return authUser.token
            })
    }

    @action
    clearAuth() {
        this.hideConfirmLogoutModal()
        this.logoutLoading = false
        this.authUser = null
        AuthService.clearAuth()
        this.clearTimeout()
    }

    @action
    logout() {
        this.logoutLoading = true
        if (
            this.authUser?.token &&
            AuthStore.isValidSessionTime(AuthStore.sessionTime(this.authUser?.expiration))
        ) {
            this.authService
                .logout()
                .then(() => {
                    this.clearAuth()
                })
                .catch(() => {
                    this.clearAuth()
                })
        } else {
            this.clearAuth()
        }
    }

    @computed
    // eslint-disable-next-line
    get isAuthenticated() {
        return (
            this.authUser !== null &&
            AuthStore.isValidSessionTime(AuthStore.sessionTime(this.authUser?.expiration))
        )
    }
}

export default AuthStore

import {
    ADDITIONAL_PERSONAL_DATA,
    ALTERNATIVE_HOME,
    HOME,
    PERSONAL_DATA,
    PRE_CREDIT_APPROVED,
    PRE_CREDIT_REJECTED,
    PRE_CREDIT_SEND_LATER,
    RESULT,
    VERIFYING,
} from 'modules/Client/routing/clientPaths'
import {
    DEALERSHIP as DEALERSHIP_ROUTE,
    DEALERSHIP_ADDITIONAL_PERSONAL_DATA,
    DEALERSHIP_ALTERNATIVE_HOME,
    DEALERSHIP_HOME,
    DEALERSHIP_PERSONAL_DATA,
    DEALERSHIP_PRE_CREDIT_APPROVED,
    DEALERSHIP_PRE_CREDIT_REJECTED,
    DEALERSHIP_PRE_CREDIT_SEND_LATER,
    DEALERSHIP_RESULT,
    DEALERSHIP_VERIFYING,
} from 'modules/Dealership/routing/dealershipPaths'
import { USER as USER_ROUTE } from 'modules/User/routing/userPaths'

export const CLIENT = '/'
export const DEALERSHIP = DEALERSHIP_ROUTE
export const USER = USER_ROUTE
export const PRIVACY_AUTH = '/privacidad'

export const getRoute = (isAuth, path) => {
    if (isAuth) {
        switch (path) {
            case HOME:
                return DEALERSHIP_HOME
            case ALTERNATIVE_HOME:
                return DEALERSHIP_ALTERNATIVE_HOME
            case RESULT:
                return DEALERSHIP_RESULT
            case PRE_CREDIT_APPROVED:
                return DEALERSHIP_PRE_CREDIT_APPROVED
            case PRE_CREDIT_REJECTED:
                return DEALERSHIP_PRE_CREDIT_REJECTED
            case ADDITIONAL_PERSONAL_DATA:
                return DEALERSHIP_ADDITIONAL_PERSONAL_DATA
            case PERSONAL_DATA:
                return DEALERSHIP_PERSONAL_DATA
            case VERIFYING:
                return DEALERSHIP_VERIFYING
            case PRE_CREDIT_SEND_LATER:
                return DEALERSHIP_PRE_CREDIT_SEND_LATER
            default:
                return path
        }
    }
    return path
}

export default {
    CLIENT,
    DEALERSHIP,
    USER,
}

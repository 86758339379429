export const DEALERSHIP = '/concesionaria'

// dealership routes
export const DEALERSHIP_HOME = `${DEALERSHIP}`
export const DEALERSHIP_LOGIN = `${DEALERSHIP}/login`
export const DEALERSHIP_EXTERNAL_LOGIN = `${DEALERSHIP_LOGIN}/externo`
export const DEALERSHIP_ALTERNATIVE_HOME = `${DEALERSHIP}/home`
export const DEALERSHIP_RESULT = `${DEALERSHIP}/resultado`
export const DEALERSHIP_PRE_CREDIT_APPROVED = `${DEALERSHIP}/credito-pre-aprobado`
export const DEALERSHIP_CONTACT = `${DEALERSHIP}/contacto`
export const DEALERSHIP_PRE_CREDIT_REJECTED = `${DEALERSHIP}/credito-rechazado`
// export const DEALERSHIP_PRIVACY_POLICIES = `${DEALERSHIP}/politicas-privacidad`
export const DEALERSHIP_ADDITIONAL_PERSONAL_DATA = `${DEALERSHIP}/datos-laborales`
export const DEALERSHIP_PERSONAL_DATA = `${DEALERSHIP}/datos-personales`
export const DEALERSHIP_VERIFYING = `${DEALERSHIP}/verificando`
export const DEALERSHIP_PRE_CREDIT_SEND_LATER = `${DEALERSHIP}/enviaremos-informacion`
export const DEALERSHIP_CALCULATOR_INVERTED_MODE = `${DEALERSHIP}/calculadora-invertida`
export const DEALERSHIP_SUMMARY_SENT = `${DEALERSHIP}/envio-cotizacion`

// ROUTE ORDER BY NAME :) !!
export default {
    DEALERSHIP,
    DEALERSHIP_LOGIN,
    DEALERSHIP_EXTERNAL_LOGIN,
    // DEALERSHIP_PRIVACY_POLICIES,
    DEALERSHIP_HOME,
    DEALERSHIP_ALTERNATIVE_HOME,
    DEALERSHIP_CONTACT,
    DEALERSHIP_RESULT,
    DEALERSHIP_PRE_CREDIT_APPROVED,
    DEALERSHIP_PRE_CREDIT_REJECTED,
    DEALERSHIP_ADDITIONAL_PERSONAL_DATA,
    DEALERSHIP_PERSONAL_DATA,
    DEALERSHIP_VERIFYING,
    DEALERSHIP_PRE_CREDIT_SEND_LATER,
    DEALERSHIP_SUMMARY_SENT,
}

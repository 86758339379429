export const HOME = '/'
export const FAQ = '/preguntas-frecuentes'
export const ABOUT_US = '/quienes-somos'
export const ALTERNATIVE_HOME = '/home'
export const RESULT = '/resultado'
export const PRE_CREDIT_APPROVED = '/credito-pre-aprobado'
export const PRE_CREDIT_REJECTED = '/credito-rechazado'
export const ADDITIONAL_PERSONAL_DATA = '/datos-personales-adicionales'
export const PERSONAL_DATA = '/datos-personales'
export const VERIFYING = '/verificando'
export const PRE_CREDIT_SEND_INFO = '/contacto'
export const PRE_CREDIT_SEND_LATER = '/enviaremos-informacion'

export const isBrandRoute = route => {
    return route.includes(FAQ) || route.includes(ABOUT_US)
}

// ROUTE ORDER BY NAME :) !!
export default {
    ABOUT_US,
    ADDITIONAL_PERSONAL_DATA,
    ALTERNATIVE_HOME,
    FAQ,
    HOME,
    PERSONAL_DATA,
    PRE_CREDIT_APPROVED,
    PRE_CREDIT_REJECTED,
    PRE_CREDIT_SEND_INFO,
    PRE_CREDIT_SEND_LATER,
    RESULT,
    VERIFYING,
}

import i18n from 'core/util/i18n'

export const APPROVED = 'approved'
export const REJECTED = 'rejected'
export const PENDING = 'pending'

export const BASIC_INSTALLMENT_TYPE = 'installment'
export const INVERTED_INSTALLMENT_TYPE = 'finance'

export const MAX_TIMEOUT_TIME = 2147483647

export const MIN_CREDIT_VALUE = 1000000

export const MIN_CAR_VALUE = 1000000
export const MAX_CAR_VALUE = 10000000

export const MIN_AGE = 25
export const MAX_AGE = 75

export const DEALERSHIP_USER = 'dealership_user'
export const CLIENT_USER = 'client_user'

export const DEBOUNCE_INPUTS_TIME = parseInt(process.env.REACT_APP_DEBOUNCE_INPUTS_TIME || 1000, 0)

export const DEPENDENT = 'dependent'
export const INDEPENDENT = 'independent'

export const LEAD_SOURCE_PARAM = 'OLXAutos_financiamiento'

export const LESS_THAN_SIX_MONTHS = 'lessThanSixMonths'
export const LESS_THAN_TWELVE_MONTHS = 'lessThanTwelveMonths'
export const MORE_THAN_SIX_MONTHS = 'moreThanSixMonths'
export const MORE_THAN_TWELVE_MONTHS = 'moreThanTwelveMonths'

export const MIN_TYPE_VALIDATION = 'min'
export const MAX_TYPE_VALIDATION = 'max'

// VALIDATIONS
export const REQUIRED = 'field_required'
export const EMAIL = 'field_email'
export const PHONE = 'field_phone'
export const RUT = 'field_rut'
export const DATE = 'field_birth'
export const CODE = 'field_code'
export const FIELD_AGE = 'field_age'
export const MIN_REQUIRED = 'min_required'
export const MAX_EXCEED = 'max_exceed'
export const FIELD_MIN_RENT_AMOUNT = 'field_min_rent_amount'
export const FIELD_WORK_TIME_LESS_THAN_SIX_MONTHS = 'field_work_time_less_than_six_months'
export const FIELD_WORK_TIME_LESS_THAN_TWELVE_MONTHS = 'field_work_time_less_than_twelve_months'
export const FIELD_PASSWORD = 'field_password'
export const FIELD_AT_LEAST_DIGIT = 'field_at_least_digit'
export const FIELD_AT_LEAST_MINUS = 'field_at_least_minus'
export const FIELD_AT_LEAST_MAYUS = 'field_at_least_mayus'
export const FIELD_AT_LEAST_CHARS = 'field_at_least_chars'
export const FIELD_CAR_VALUE_MIN_MAX = 'field_carValue_min_max'

// PRE-CREDIT APPROVED ACTIONS
export const PRE_CREDIT_APPROVED_ACCOUNT_ACTION = 'account'
export const PRE_CREDIT_APPROVED_SIMULATE_ACTION = 'simulation'
export const PRE_CREDIT_APPROVED_WHATSAPP_ACTION = 'whatsapp'

// ERRORS
export const GENERIC_REQUEST_ERROR = 'generic_error'

// FAMILI MEMBERS
export const AVAILABLE_MEMBERS = [
    { id: 'spouse', name: i18n.t('spouse') },
    { id: 'parent', name: i18n.t('parent') },
    { id: 'son', name: i18n.t('son') },
]

// DOCUMENTS STATUSES
export const DOCUMENT_SECTION_APPROVED = 'approved'
export const DOCUMENT_SECTION_REJECTED = 'rejected'
export const DOCUMENT_SECTION_PENDING = 'pending'
export const DOCUMENT_UPLOAD_PENDING = 'uploadedPending'

// COOKIES
export const APP_VERSION_COOKIE = 'appVersion'

export const INSTALLMENTS = [12, 24, 36, 48]

// CALCULATOR TYPES
export const CALCULATOR_BASIC_MODE = 'calculator_basic_mode'
export const CALCULATOR_INVERTED_MODE = 'calculator_inverted_mode'

export const BASIC_MODE_PLAN = 'basic'
export const INVERTED_MODE_PLAN = 'inverted'

// URLS
export const CONTACT_URL = 'https://www.olxautos.cl/contacto'
export const PAYONLINE_URL = 'https://pay.upago.cl/go/olx-autos-financiamiento-inmediato'

// ORDER SENSES
export const ORDER_ASC = 'asc'
export const ORDER_DESC = 'desc'

// MAX CAR AGE INSTALLMENTS
export const MAX_CAR_AGE_DEFAULT = 50

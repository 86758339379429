import QuoteOption from 'core/models/QuoteOption'
import { action, computed, observable, runInAction } from 'mobx'
import SelectQuoteService from 'modules/User/services/SelectQuoteService'
import moment from 'moment'

class AuthUser {
    @observable currentAgreedLoan
    @observable agreedLoans = []
    @observable isLoadingAgreedLoans
    @observable askCarQuestion

    constructor(id, email, token, currentAgreedLoan, agreedLoans, askCarQuestion, expiration) {
        this.id = id
        this.email = email
        this.token = token
        this.currentAgreedLoan = currentAgreedLoan
        this.agreedLoans = agreedLoans
        this.selectQuoteService = new SelectQuoteService()
        this.askCarQuestion = askCarQuestion
        this.expiration = expiration
    }

    updateAuth(token, expiration) {
        this.token = token
        this.expiration = expiration
    }

    setAskCarQuestion(value) {
        this.askCarQuestion = value
    }

    @action
    setCurrentAgreedLoan(currentAgreedLoan) {
        this.currentAgreedLoan = currentAgreedLoan
    }

    get noAgreedLoans() {
        return this.agreedLoans.length === 0
    }

    @action
    async getAgreedLoans() {
        this.isLoadingAgreedLoans = true
        try {
            const response = await this.selectQuoteService.getQuotes()

            runInAction(() => {
                this.agreedLoans = response
                this.isLoadingAgreedLoans = false
            })
        } catch (e) {
            console.log(e)
            this.isLoadingAgreedLoans = false
        }
    }

    @computed
    get selectedLoan() {
        if (this.agreedLoans.length && this.currentAgreedLoan) {
            const [selected] = this.agreedLoans.filter(
                option => option.id === this.currentAgreedLoan
            )

            return selected
        }

        return null
    }

    @computed
    get canUploadDocuments() {
        if (this.agreedLoans.length && this.currentAgreedLoan) {
            const [selected] = this.agreedLoans.filter(
                option => option.id === this.currentAgreedLoan
            )

            return !(selected.isRejected || selected.isApproved)
        }

        return true
    }

    // eslint-disable-next-line
    static fromJson({ id, email, accessToken, expiresAt, currentAgreedLoan, askCarQuestion, agreedLoans = []}) {
        const authUser = new AuthUser(
            id,
            email,
            accessToken,
            currentAgreedLoan,
            agreedLoans.map(quote => QuoteOption.fromJson(quote)),
            askCarQuestion,
            moment(expiresAt).unix()
        )

        return authUser
    }
}

export default AuthUser

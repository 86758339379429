import React from 'react'

export const ClientModule = React.lazy(() =>
    import(/* webpackChunkName: "ClientModule" */ 'modules/Client')
)

export const DealershipModule = React.lazy(() =>
    import(/* webpackChunkName: "DealershipModule" */ 'modules/Dealership')
)

export const PrivacyAuthComponent = React.lazy(() =>
    import(/* webpackChunkName: "PrivacyAuth" */ 'core/scenes/PrivacyAuth')
)

export const UserModule = React.lazy(() =>
    import(/* webpackChunkName: "UserModule" */ 'modules/User')
)
